<template>
    <v-container fluid class="ma-0 pa-0">
        <v-progress-linear
            v-if="loading"
            class="mb-8"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit">
                <v-row v-if="approvedDiary">
                    <v-col cols="12">
                        <v-alert
                            text
                            outlined
                            type="success"
                        >
                            Esta diária está <strong>aprovada!</strong>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="precisaSincronizarPrecos">
                    <v-col cols="12" md="2">
                        <v-btn
                            @click="sincronizarPrecos"
                            class="success"
                        >Sinc
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="2">
                        <v-text-field 
                            v-if="method=='create'"
                            ref="dateInput"
                            v-model="fields.Data" 
                            type="date" 
                            id="birthday" 
                            label="Insira data"
                            dense outlined
                            :max="maxDate"
                            :rules=[validateDate]
                            @click="openNativeDatePicker"
                            @focus="openNativeDatePicker"
                            @mousedown.prevent="openNativeDatePicker"
                        />

                        <v-text-field
                            v-else
                            dense outlined
                            v-model="fields.Data"
                            disabled
                            label="Data"
                        />
                    </v-col>
                    <v-col cols="12" md="4" v-if="fields.Data != null">
                        <validation-provider v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.obra_id"
                                :items="obras"
                                outlined dense
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                item-value="Id"
                                :error-messages="errors"
                                label="Obras *"
                                @change="changeWork"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="false">
                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.frente_id"
                                :items="frentes_obra"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Frentes de Obra *"
                                @change="changeFrenteObra"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="false">
                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.classe_id"
                                :items="classes"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Classe *"
                                @change="changeClasse"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row v-if="showContent" class="ma-0 pa-0">
                    <v-col cols="12" v-if="method=='create'">
                        <v-btn rounded class="black white--text" @click="importTemplates" v-if="template != null">
                            <v-icon>mdi-paperclip</v-icon>
                            Template
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-tabs
                            class="d-none d-lg-block"
                            v-model="tab"
                            dark
                            fixed-tabs
                            background-color="primary"
                            icons-and-text                            
                        >
                            <v-tab :key="0" :disabled="$store.getters.hasRole(['parque'])">
                                Mão Obra
                                <v-icon>mdi-account-hard-hat</v-icon>
                            </v-tab>
                            <v-tab :key="1">
                                Equipamento
                                <v-icon>mdi-bulldozer</v-icon>
                            </v-tab>
                            <v-tab :key="2">
                                Material
                                <v-icon>mdi-archive</v-icon>
                            </v-tab>
                            <v-tab :key="3" class="text-caption">
                                Diárias KPI
                                <v-icon>mdi-hard-hat</v-icon>
                            </v-tab>
                            <v-tab :key="4">
                                Subempreitadas
                                <v-icon>mdi-briefcase</v-icon>
                            </v-tab>
                            <v-tab :key="5">
                                    Observações
                                <v-badge
                                    v-if="fields.obsMO != null || fields.obsEQ != null"
                                    color="error"
                                    dot
                                    overlap
                                >
                                    <v-icon>mdi-eye</v-icon>
                                </v-badge>
                                <v-icon v-else>mdi-eye</v-icon>
                            </v-tab>
                        </v-tabs>
                        <v-tabs
                            class="d-block d-lg-none"
                            v-model="tab"
                            dark
                            fixed-tabs
                            background-color="primary"
                            icons-and-text                            
                        >
                            <v-tab :key="0" class="text-caption">
                                Mão Obra
                                <v-icon>mdi-account-hard-hat</v-icon>
                            </v-tab>
                            <v-tab :key="1" class="text-caption">
                                Equipamento
                                <v-icon>mdi-bulldozer</v-icon>
                            </v-tab>
                            <v-tab :key="2" class="text-caption">
                                Material
                                <v-icon>mdi-archive</v-icon>
                            </v-tab>
                            <v-tab :key="3" class="text-caption">
                                Diárias KPI
                                <v-icon>mdi-hard-hat</v-icon>
                            </v-tab>
                            <v-tab :key="4" class="text-caption">
                                Subempreitadas
                                <v-icon>mdi-briefcase</v-icon>
                            </v-tab>
                            <v-tab :key="5" class="text-caption">
                                Observações
                                <v-icon>mdi-eye</v-icon>
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <!-- Mão Obra Interna -->
                                <v-container fluid class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="ma-0 pa-0">
                                            <v-card class="elevation-0">
                                                <v-card-title class="d-none d-lg-block warning white--text">
                                                    Mão Obra Interna
                                                    <div>
                                                        <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInput">
                                                            Importar Excel
                                                        </v-btn>
                                                        <input  v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                                                        type="file" 
                                                        ref="fileInput" 
                                                        @change="handleFileUpload" 
                                                        accept=".xls,.xlsx" 
                                                        style="display: none" 
                                                        />
                                                    </div>
                                                </v-card-title>
                                                <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                                                    Mão Obra Interna
                                                    <div>
                                                        <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInput">
                                                            Importar Excel
                                                        </v-btn>
                                                        <input  v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                                                        type="file" 
                                                        ref="fileInput" 
                                                        @change="handleFileUpload" 
                                                        accept=".xls,.xlsx" 
                                                        style="display: none" 
                                                        />
                                                    </div>
                                                </v-card-title>
                                                <v-card-text class="ma-0 pa-0">
                                                    <v-simple-table class="d-none d-lg-block ma-0 pa-0" fixed-header>
                                                        <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-left" width="25%">
                                                                        Nome
                                                                    </th>
                                                                    <th class="text-left" width="25%">
                                                                        Frente Obra
                                                                    </th>
                                                                    <th class="text-left" width="20%">
                                                                        Classe
                                                                    </th>
                                                                    <th class="text-left" width="10%">
                                                                        HN
                                                                    </th>
                                                                    <th class="text-left" width="10%">
                                                                        HExt
                                                                    </th>
                                                                    <th v-if="$store.getters.hasRole(['super', 'administrativo', 'engenheiro'])" width="8%">
                                                                        Faturar
                                                                    </th>
                                                                    <th class="text-left" width="2%">
                                                                        Opções
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    v-for="(item, index) in computeFuncionariosInternos" :key="index+10001"
                                                                    :class="item.id.includes('non-editable') ? 'my-orange' : (verificarCustosLinha(item) == true ? '' : 'my-grey')"
                                                                >
                                                                    <td>         
                                                                        <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario" name="funcionario" rules="required">                                                           
                                                                            <Tooltip
                                                                                color="primary"
                                                                                :text="getEspecialidade('interno', item.funcionario)"
                                                                                top
                                                                            >
                                                                                <v-autocomplete
                                                                                    class="mt-4"
                                                                                    :disabled="item.id.includes('non-editable')"
                                                                                    outlined dense
                                                                                    :items="item.funcionarios_disponiveis"
                                                                                    :item-text="item => item.Codigo + ' - ' + item.Nome"
                                                                                    item-value="Codigo"
                                                                                    label="Funcionário"
                                                                                    :error-messages="errors"
                                                                                    v-model="item.funcionario"
                                                                                    @change="calculateMaoObraCost(item, 'i')"
                                                                                />
                                                                            </Tooltip>
                                                                        </validation-provider>                                                                        
                                                                        <Tooltip
                                                                            v-else
                                                                            color="primary"
                                                                            :text="item.especialidade"
                                                                            top
                                                                        >
                                                                            <v-text-field
                                                                                dense outlined
                                                                                class="mt-4"
                                                                                label="Funcionario"
                                                                                :disabled="true"
                                                                                :value="item.funcionario + ' - ' + item.Nome"
                                                                            />
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="showFrente(item)">
                                                                            <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                                <v-autocomplete                                                                                
                                                                                    v-model="item.frente_id"
                                                                                    :items="frentes_obra"
                                                                                    outlined dense
                                                                                    :disabled="item.id.includes('non-editable')"
                                                                                    class="mt-4"
                                                                                    :item-text="ob => ob.Descricao"
                                                                                    item-value="ID"
                                                                                    :error-messages="errors"
                                                                                    label="Frentes de Obra *"
                                                                                    @change="changeFrenteObraV2(item)"
                                                                                />
                                                                            </validation-provider>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="showClasse(item)">
                                                                            <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                                <v-autocomplete
                                                                                    v-model="item.classe_id"
                                                                                    :items="item.available_classes"
                                                                                    class="mt-4"
                                                                                    :disabled="item.id.includes('non-editable')"
                                                                                    outlined dense
                                                                                    :item-text="ob => ob.Descricao"
                                                                                    item-value="ID"
                                                                                    :error-messages="errors"
                                                                                    label="Classe *"
                                                                                />
                                                                            </validation-provider>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <v-text-field 
                                                                            class="mt-4"
                                                                            label="HN"
                                                                            outlined dense
                                                                            :disabled="item.id.includes('non-editable')"
                                                                            placeholder="Insira HN"
                                                                            v-model="item.hn"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <validation-provider v-slot="{ errors }" vid="HExt" name="HExt" rules="required">
                                                                            <v-text-field 
                                                                                class="mt-4"
                                                                                label="HExt"
                                                                                :error-messages="errors"
                                                                                :disabled="item.id.includes('non-editable')"
                                                                                outlined dense
                                                                                placeholder="Insira HExt"
                                                                                v-model="item.hext"
                                                                            />
                                                                        </validation-provider>
                                                                    </td>
                                                                    <td v-if="$store.getters.hasRole(['super', 'engenheiro', 'administrativo'])">
                                                                        <v-autocomplete
                                                                            label="Faturar"
                                                                            :items="item.faturar_values"
                                                                            v-model="item.faturar"
                                                                            :disabled="item.id.includes('non-editable')"
                                                                            dense outlined
                                                                            class="mt-4"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <v-speed-dial
                                                                            v-if="!item.id.includes('non-editable')"
                                                                            v-model="fabOptions[item.id]"
                                                                            direction="left"
                                                                            style="position: absolute; transform: translateY(-50%)"
                                                                        >
                                                                            <template v-slot:activator>                                                                                
                                                                                <v-btn
                                                                                     v-if="fabOptions[item.id]" 
                                                                                    :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="openEditorModal(item, 'funcionario_interno')">
                                                                                    <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                                                    <v-badge
                                                                                        v-else
                                                                                        color="orange"
                                                                                        dot
                                                                                        overlap
                                                                                    >
                                                                                        <v-icon>mdi-note-plus</v-icon>
                                                                                    </v-badge>
                                                                                </v-btn>
                                                                                <v-badge
                                                                                    :color="(item.local == null || item.local == '') ? 'transparent' : 'primary'"
                                                                                    dot
                                                                                    overlap
                                                                                >
                                                                                    <v-btn
                                                                                        v-if="!fabOptions[item.id]"
                                                                                        small
                                                                                        v-model="fabOptions[item.id]"
                                                                                        color="warning"
                                                                                        dark
                                                                                        fab
                                                                                    >
                                                                                        <v-icon>
                                                                                            mdi-dots-horizontal
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </v-badge>
                                                                                <v-btn
                                                                                    v-if="fabOptions[item.id]"
                                                                                    fab
                                                                                    dark
                                                                                    small
                                                                                    color="error"
                                                                                    @click="deleteFuncionarioInterno(item.id)"
                                                                                >
                                                                                    <v-icon>mdi-delete</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            
                                                                        </v-speed-dial>
                                                                    </td>
                                                                
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>

                                                    <v-data-iterator
                                                        :items="fields.funcionariosInternos"
                                                        item-key="id"
                                                        :items-per-page="-1"
                                                        hide-default-footer
                                                        class="d-block d-lg-none ma-0 pa-0"
                                                    >
                                                    <template v-slot:default="{ items }">
                                                        <v-row class="ma-0 pa-0">
                                                            <v-col
                                                                class="ma-0 pa-0"
                                                                v-for="(item, index) in items"
                                                                :key="index+200000"
                                                                cols="12"
                                                            >
                                                                <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                                                    <v-list
                                                                        dense
                                                                    >
                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario" name="funcionario" rules="required">
                                                                                    <v-autocomplete
                                                                                        class="mt-4"
                                                                                        outlined dense
                                                                                        :disabled="item.id.includes('non-editable')"
                                                                                        :items="funcionarios_internos"
                                                                                        :item-text="item => item.Codigo + ' - ' + item.Nome"
                                                                                        item-value="Codigo"
                                                                                        label="Funcionário"
                                                                                        :error-messages="errors"
                                                                                        v-model="item.funcionario"
                                                                                        @change="calculateMaoObraCost(item, 'i')"
                                                                                    />
                                                                                </validation-provider>
                                                                                <v-text-field
                                                                                    v-else
                                                                                    dense outlined
                                                                                    class="mt-4"
                                                                                    label="Funcionario"
                                                                                    :disabled="true"
                                                                                    :value="item.funcionario + ' - ' + item.Nome"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <div v-if="showFrente(item)">
                                                                                    <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                                        <v-autocomplete                                                                                
                                                                                            v-model="item.frente_id"
                                                                                            :items="frentes_obra"
                                                                                            outlined dense
                                                                                            class="mt-4"
                                                                                            :item-text="ob => ob.Descricao"
                                                                                            :disabled="item.id.includes('non-editable')"
                                                                                            item-value="ID"
                                                                                            :error-messages="errors"
                                                                                            label="Frentes de Obra *"
                                                                                            @change="changeFrenteObraV2(item)"
                                                                                        />
                                                                                    </validation-provider>
                                                                                </div>
                                                                                <span v-else>{{item.frente_obra == null ? '-' : item.frente_obra}}</span>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <div v-if="showClasse(item)">
                                                                                    <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                                        <v-autocomplete
                                                                                            v-model="item.classe_id"
                                                                                            :items="item.available_classes"
                                                                                            class="mt-4"
                                                                                            :disabled="item.id.includes('non-editable')"
                                                                                            outlined dense
                                                                                            :item-text="ob => ob.Descricao"
                                                                                            item-value="ID"
                                                                                            :error-messages="errors"
                                                                                            label="Classe *"
                                                                                            @change="changeClasse"
                                                                                        />
                                                                                    </validation-provider>
                                                                                </div>
                                                                                <span v-else>{{item.classe_obra == null ? '-' : item.classe_obra}}</span>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field 
                                                                                    class="mt-4"
                                                                                    :disabled="item.id.includes('non-editable')"
                                                                                    label="HN"
                                                                                    outlined dense
                                                                                    placeholder="Insira HN"
                                                                                    v-model="item.hn"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">                                                                                
                                                                                <validation-provider v-slot="{ errors }" vid="HExt" name="HExt" rules="required">
                                                                                    <v-text-field 
                                                                                        class="mt-4"
                                                                                        :disabled="item.id.includes('non-editable')"
                                                                                        label="HExt"
                                                                                        outlined dense
                                                                                        :error-messages="errors"
                                                                                        placeholder="Insira HExt"
                                                                                        v-model="item.hext"
                                                                                    />
                                                                                </validation-provider>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-btn v-if="!item.id.includes('non-editable')" :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" rounded small @click="openEditorModal(item, 'funcionario_interno')">
                                                                                    <v-icon small v-if="item.local == null || item.local == ''">mdi-plus</v-icon>
                                                                                    <v-badge
                                                                                        v-else
                                                                                        color="orange"
                                                                                        dot
                                                                                        overlap
                                                                                    >
                                                                                        <v-icon small>mdi-eye</v-icon>
                                                                                    </v-badge>
                                                                                    Nota
                                                                                </v-btn>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-btn
                                                                                    dark
                                                                                    small
                                                                                    color="error"
                                                                                    @click="deleteFuncionarioInterno(item.id)"
                                                                                >
                                                                                    <v-icon small>mdi-delete</v-icon> Remover
                                                                                </v-btn>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    </v-data-iterator>
                                                    

                                                    <v-row justify="center" class="mt-4">
                                                        <v-col cols="4" class="text-left">
                                                            <v-btn class="primary" small fab @click="paginacaoInterna.menor == 0 ? paginacaoInterna.menor = 0 : paginacaoInterna.menor-=step;paginacaoInterna.maior-=step">
                                                                <v-icon>mdi-chevron-left</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="4" align="center" class="text-center">
                                                            <v-btn fab @click="addFuncionarioInterno" class="primary">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="4" class="text-right">
                                                            <v-btn class="primary" small fab @click="paginacaoInterna.menor+=step;paginacaoInterna.maior+=step">
                                                            <v-icon>mdi-chevron-right</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <!-- Mão Obra Interna -->

                                <!-- Mão Obra Externa -->
                                <v-container fluid>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-card class="elevation-0">
                                                <v-card-title class="d-none d-lg-block warning white--text">
                                                    Mão Obra Externa 
                                                    <div>
                                                        <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInputExternal">
                                                            Importar Excel
                                                        </v-btn>
                                                        <input v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                                                            type="file" 
                                                            ref="fileInputExternal" 
                                                            @change="handleFileUploadExternal" 
                                                            accept=".xls,.xlsx" 
                                                            style="display: none" 
                                                        />
                                                        <v-btn
                                                            style="float: right;"
                                                            x-small
                                                            class="primary"
                                                            @click="entitySelect = false;entitySelect = true"
                                                        ><v-icon small>mdi-magnify</v-icon>Entidade
                                                        </v-btn>
                                                    </div>
                                                </v-card-title>
                                                <v-card-title class="d-block d-lg-none warning white--text text-caption text-center">
                                                    Mão Obra Externa
                                                    <div>
                                                        <v-btn v-if="$store.getters.hasPermission(['super', 'diaries.import'])" x-small class="primary" elevation="0" @click="triggerFileInputExternal">
                                                            Importar Excel
                                                        </v-btn>
                                                        <input v-if="$store.getters.hasPermission(['super', 'diaries.import'])"
                                                            type="file" 
                                                            ref="fileInputExternal" 
                                                            @change="handleFileUploadExternal" 
                                                            accept=".xls,.xlsx" 
                                                            style="display: none" 
                                                        />
                                                        <v-btn
                                                            style="float: right;"
                                                            x-small
                                                            class="primary"
                                                            @click="entitySelect = false;entitySelect = true"
                                                        ><v-icon small>mdi-magnify</v-icon>Entidade
                                                        </v-btn>
                                                    </div>
                                                </v-card-title>
                                                <v-card-text class="ma-0 pa-0">
                                                    <v-simple-table class="d-none d-lg-block ma-0 pa-0" fixed-header>
                                                        <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-left" width="15%">
                                                                        Entidade
                                                                    </th>
                                                                    <th class="text-left" width="19%">
                                                                        Nome
                                                                    </th>
                                                                    <th class="text-left" width="19%">
                                                                        Frente Obra
                                                                    </th>
                                                                    <th class="text-left" width="19%">
                                                                        Classe
                                                                    </th>
                                                                    <th class="text-left" width="9%">
                                                                        HN
                                                                    </th>
                                                                    <th class="text-left" width="9%">
                                                                        HExt
                                                                    </th>
                                                                    <th v-if="$store.getters.hasRole(['super', 'administrativo', 'engenheiro'])" width="7%">
                                                                        Faturar
                                                                    </th>
                                                                    <th class="text-left" width="2%">
                                                                        Opções
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    v-for="(item, index) in computeFuncionariosExternos" :key="index + 800000"
                                                                    :class="verificarCustosLinha(item) == true ? 'mt-4' : 'my-grey mt-4'"
                                                                >
                                                                    <td>         
                                                                        <v-text-field 
                                                                            v-if="item.entidade != null"
                                                                            class="mt-4"
                                                                            readonly
                                                                            dense outlined
                                                                            :value="item.entidade.Codigo + ' - ' + item.entidade.Nome"
                                                                            label="Entidade"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario_externo" name="funcionario_externo" rules="required">                                                                                                                          
                                                                            <Tooltip
                                                                                color="primary"
                                                                                :text="getEspecialidade('externo', item.funcionario)"
                                                                                top
                                                                            >
                                                                                <v-autocomplete
                                                                                    class="mt-4"
                                                                                    outlined dense
                                                                                    :items="funcionarios_externos"
                                                                                    :item-text="item => item.Numero + ' - ' + item.Nome"
                                                                                    item-value="Numero"
                                                                                    label="Funcionário"
                                                                                    :error-messages="errors"
                                                                                    v-model="item.funcionario"
                                                                                    @change="changedExterno; calculateMaoObraCost(item, 'e')"
                                                                                />
                                                                            </Tooltip>
                                                                        </validation-provider>
                                                                        <Tooltip
                                                                            v-else
                                                                            color="primary"
                                                                            :text="item.especialidade"
                                                                            top
                                                                        >
                                                                            <v-text-field
                                                                                dense outlined
                                                                                class="mt-4"
                                                                                label="Funcionario"
                                                                                :disabled="true"
                                                                                :value="item.funcionario + ' - ' + item.Nome"
                                                                            />
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="showFrente(item)">
                                                                            <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                                <v-autocomplete                                                                                
                                                                                    v-model="item.frente_id"
                                                                                    :items="frentes_obra"
                                                                                    outlined dense
                                                                                    class="mt-4"
                                                                                    :item-text="ob => ob.Descricao"
                                                                                    item-value="ID"
                                                                                    :error-messages="errors"
                                                                                    label="Frentes de Obra *"
                                                                                    @change="changeFrenteObraV2(item)"
                                                                                />
                                                                            </validation-provider>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="showClasse(item)">
                                                                            <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                                <v-autocomplete
                                                                                    v-model="item.classe_id"
                                                                                    :items="item.available_classes"
                                                                                    class="mt-4"
                                                                                    outlined dense
                                                                                    :item-text="ob => ob.Descricao"
                                                                                    item-value="ID"
                                                                                    :error-messages="errors"
                                                                                    label="Classe *"
                                                                                />
                                                                            </validation-provider>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <v-text-field 
                                                                            class="mt-4"
                                                                            outlined dense
                                                                            label="HN"
                                                                            placeholder="Insira HN"
                                                                            v-model="item.hn"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <validation-provider v-slot="{ errors }" vid="HExt" name="HExt" rules="required">
                                                                            <v-text-field 
                                                                                class="mt-4"
                                                                                outlined dense
                                                                                :error-messages="errors"
                                                                                label="HExt"
                                                                                placeholder="Insira HExt"
                                                                                v-model="item.hext"
                                                                            />
                                                                        </validation-provider>
                                                                    </td>

                                                                    <td v-if="$store.getters.hasRole(['super', 'engenheiro', 'administrativo'])">
                                                                        <v-autocomplete
                                                                            label="Faturar"
                                                                            :items="item.faturar_values"
                                                                            v-model="item.faturar"
                                                                            dense outlined
                                                                            class="mt-4"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <v-speed-dial
                                                                            v-model="fabOptions[item.id]"
                                                                            direction="left"
                                                                            style="position: absolute; transform: translateY(-50%)"
                                                                        >
                                                                            <template v-slot:activator>                                                                                
                                                                                <v-btn
                                                                                     v-if="fabOptions[item.id]" 
                                                                                    :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" fab small @click="openEditorModal(item, 'funcionario_externo')">
                                                                                    <v-icon v-if="item.local == null || item.local == ''">mdi-note-edit</v-icon>
                                                                                    <v-badge
                                                                                        v-else
                                                                                        color="orange"
                                                                                        dot
                                                                                        overlap
                                                                                    >
                                                                                        <v-icon>mdi-note-plus</v-icon>
                                                                                    </v-badge>
                                                                                </v-btn>
                                                                                <v-badge
                                                                                    :color="(item.local == null || item.local == '') ? 'transparent' : 'primary'"
                                                                                    dot
                                                                                    overlap
                                                                                >
                                                                                    <v-btn
                                                                                        v-if="!fabOptions[item.id]"
                                                                                        small
                                                                                        v-model="fabOptions[item.id]"
                                                                                        color="warning"
                                                                                        dark
                                                                                        fab
                                                                                    >
                                                                                        <v-icon>
                                                                                            mdi-dots-horizontal
                                                                                        </v-icon>
                                                                                    </v-btn>
                                                                                </v-badge>
                                                                                <v-btn
                                                                                    v-if="fabOptions[item.id]"
                                                                                    fab
                                                                                    dark
                                                                                    small
                                                                                    color="error"
                                                                                    @click="deleteFuncionarioExterno(item.id)"
                                                                                >
                                                                                    <v-icon>mdi-delete</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            
                                                                        </v-speed-dial>
                                                                    </td>
                                                                
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>

                                                    <v-data-iterator
                                                        :items="fields.funcionariosExternos"
                                                        item-key="id"
                                                        :items-per-page="-1"
                                                        hide-default-footer
                                                        class="d-block d-lg-none"
                                                    >
                                                    <template v-slot:default="{ items }">
                                                        <v-row>
                                                            <v-col
                                                                v-for="(item, index) in items"
                                                                :key="index+30000"
                                                                cols="12"
                                                            >
                                                                <v-card elevation="0" class="mt-2 rounded-lg" outlined>
                                                                    <v-list
                                                                        dense
                                                                    >
                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field 
                                                                                    class="mt-4"
                                                                                    v-if="item.entidade != null"
                                                                                    readonly
                                                                                    dense outlined
                                                                                    :value="item.entidade.Codigo + ' - ' + item.entidade.Nome"
                                                                                    label="Entidade"
                                                                                />
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <validation-provider v-if="toEditRow(item)" v-slot="{ errors }" vid="funcionario_externo" name="funcionario_externo" rules="required">                                                                   
                                                                                    <v-autocomplete
                                                                                        class="mt-4"
                                                                                        outlined dense
                                                                                        :items="funcionarios_externos"
                                                                                        :item-text="item => item.Numero + ' - ' + item.Nome"
                                                                                        item-value="Numero"
                                                                                        label="Funcionário"
                                                                                        :error-messages="errors"
                                                                                        v-model="item.funcionario"
                                                                                        @change="changedExterno;calculateMaoObraCost(item, 'e')"
                                                                                    />
                                                                                </validation-provider>
                                                                                <v-text-field
                                                                                    v-else
                                                                                    dense outlined
                                                                                    class="mt-4"
                                                                                    label="Funcionario"
                                                                                    :disabled="true"
                                                                                    :value="item.funcionario + ' - ' + item.Nome"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <div v-if="showFrente(item)">
                                                                                    <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                                                                                        <v-autocomplete                                                                                
                                                                                            v-model="item.frente_id"
                                                                                            :items="frentes_obra"
                                                                                            outlined dense
                                                                                            class="mt-4"
                                                                                            :item-text="ob => ob.Descricao"
                                                                                            item-value="ID"
                                                                                            :error-messages="errors"
                                                                                            label="Frentes de Obra *"
                                                                                            @change="changeFrenteObraV2(item)"
                                                                                        />
                                                                                    </validation-provider>
                                                                                </div>
                                                                            </v-list-item-content>
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <div v-if="showClasse(item)">
                                                                                    <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                                                                                        <v-autocomplete
                                                                                            v-model="item.classe_id"
                                                                                            :items="item.available_classes"
                                                                                            class="mt-4"
                                                                                            outlined dense
                                                                                            :item-text="ob => ob.Descricao"
                                                                                            item-value="ID"
                                                                                            :error-messages="errors"
                                                                                            label="Classe *"
                                                                                        />
                                                                                    </validation-provider>
                                                                                </div>
                                                                            </v-list-item-content>
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-text-field 
                                                                                    class="mt-4"
                                                                                    label="HN"
                                                                                    outlined dense
                                                                                    placeholder="Insira HN"
                                                                                    v-model="item.hn"
                                                                                />
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">                                                                                
                                                                                <validation-provider v-slot="{ errors }" vid="HExt" name="HExt" rules="required">
                                                                                    <v-text-field 
                                                                                        class="mt-4"
                                                                                        label="HExt"
                                                                                        outlined dense
                                                                                        :error-messages="errors"
                                                                                        placeholder="Insira HExt"
                                                                                        v-model="item.hext"
                                                                                    />
                                                                                </validation-provider>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>


                                                                        <v-list-item v-if="$store.getters.hasRole(['super', 'engenheiro', 'administrativo'])">
                                                                            <v-list-item-content>
                                                                                <v-autocomplete
                                                                                    label="Faturar"
                                                                                    :items="item.faturar_values"
                                                                                    v-model="item.faturar"
                                                                                    dense outlined
                                                                                    class="mt-4"
                                                                                />
                                                                            </v-list-item-content>
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-btn :class="item.local == null || item.local == '' ? 'primary elevation-0' : 'primary elevation-10'" rounded small @click="openEditorModal(item, 'funcionario_externo')">
                                                                                    <v-icon small v-if="item.local == null || item.local == ''">mdi-plus</v-icon>
                                                                                    <v-badge
                                                                                        v-else
                                                                                        color="orange"
                                                                                        dot
                                                                                        overlap
                                                                                    >
                                                                                        <v-icon small>mdi-eye</v-icon>
                                                                                    </v-badge>
                                                                                    Nota
                                                                                </v-btn>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content class="align-center">
                                                                                <v-btn
                                                                                    dark
                                                                                    small
                                                                                    color="error"
                                                                                    @click="deleteFuncionarioExterno(item.id)"
                                                                                >
                                                                                    <v-icon small>mdi-delete</v-icon> Remover
                                                                                </v-btn>
                                                                            </v-list-item-content>                                                                            
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                    </v-data-iterator>


                                                    <v-row justify="center" class="mt-4">
                                                        <v-col cols="4" class="text-left">
                                                            <v-btn class="primary" small fab @click="paginacaoExterna.menor == 0 ? paginacaoExterna.menor = 0 : paginacaoExterna.menor-=step;paginacaoExterna.maior-=step">
                                                                <v-icon>mdi-chevron-left</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="4" align="center" class="text-center">
                                                            <v-btn v-if="true" fab @click="addFuncionarioExterno" class="primary">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                            <v-btn v-if="false" fab @click="entitySelect = false;entitySelect = true" class="primary">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="4" class="text-right">
                                                            <v-btn class="primary" small fab @click="paginacaoExterna.menor+=step;paginacaoExterna.maior+=step">
                                                            <v-icon>mdi-chevron-right</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <!-- Mão Obra Externa -->
                            </v-tab-item>
                            <v-tab-item>
                                <Equipments @loader="loader=!loader" ref="equipamentos" :diarias_manobrador="diarias_manobrador" @nota="openEditorModal" :loadedClasses="classes" :hideFrentes="hideFrentes" :frentes_obra="frentes_obra" :fields="fields" return-object/>
                            </v-tab-item>
                            <v-tab-item>
                                <Materials @nota="openEditorModal" :loadedClasses="classes" :hideFrentes="hideFrentes" :frentes_obra="frentes_obra" :fields="fields" return-object/>
                            </v-tab-item>
                            <v-tab-item>
                                <DiaryKPI ref="diaria_kpi" :diary="diary_kpi" :method="diary_kpi == null ? 'create' : 'update'" :inside_diary="true" :fields_diary="fields"/>
                            </v-tab-item>
                            <v-tab-item>
                                <Subcontractors @nota="openEditorModal" :fields="fields" return-object/>
                            </v-tab-item>
                            <v-tab-item>
                                <Observations :fields="fields" return-object />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="showContent">
                    <v-col cols="12" md="1" class="d-none d-lg-block">                                
                        <v-btn v-show="fields.obra_id != null" :disabled="tab == 0" @click="tab = tab - 1" small fab class="mt-4 primary">
                            <v-icon small>mdi-less-than</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="10" align="center">
                        <v-btn v-if="canSave == 4" rounded class="success mt-4" disabled>
                            Gravar
                        </v-btn>
                        <v-btn v-else rounded class="success mt-4" :disabled="invalid" type="submit">
                            Gravar
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="1" class="d-none d-lg-block">                           
                        <v-btn v-show="fields.obra_id != null" :disabled="tab == 4" @click="tab = tab + 1" small fab class="mt-4 primary">
                            <v-icon small>mdi-greater-than</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>

    <v-dialog
        v-model="entitySelect"
        fullscreen
    >
        <v-card class="elevation-0 ma-0 pa-0" v-if="entitySelect">
            <v-toolbar
                dark
                color="primary"
            >
                <v-toolbar-title>Funcionários por entidade</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="entitySelect = false"
                >
                    X
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="mt-4 d-none d-lg-block">
                <v-row>
                    <v-col cols="12" md="6" v-if="hideFrentes == false" >
                        <v-autocomplete                                                                                                       
                            v-model="entitySelectedFrente"
                            :items="frentes_obra"
                            outlined dense
                            class="mt-4"
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Frentes de Obra *"
                            @change="changeFrenteObraV2Entity"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="entitySelectedClasse"
                            :items="entityClasses"
                            class="mt-4"
                            outlined dense
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Classe *"
                            @change="changeClasse"
                        />
                    </v-col>
                    <v-col cols="12">                        
                        <v-autocomplete
                            dense outlined
                            :items="entidades"
                            v-model="entitySelected"
                            :item-text="item=>item.Codigo + ' - ' + item.Nome"
                            item-value="ID"
                            label="Entidade"
                            @change="getFuncionariosPorEntidade"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        Pesquisa:
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            dense outlined
                            v-model="searchEntitySelected"
                            label="Nome/Número"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn
                            class="success"
                            @click="getFuncionariosPorEntidade"
                        ><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="mt-4 d-block d-lg-none">
                <v-row no-gutters>
                    <v-col cols="12">                        
                        <v-autocomplete
                            dense outlined
                            :items="entidades"
                            v-model="entitySelected"
                            :item-text="item=>item.Codigo + ' - ' + item.Nome"
                            item-value="ID"
                            label="Entidade"
                            @change="getFuncionariosPorEntidade"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-if="hideFrentes == false"                                                                            
                            v-model="entitySelectedFrente"
                            :items="frentes_obra"
                            outlined dense
                            class="mt-4"
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Frentes de Obra *"
                            @change="changeFrenteObraV2Entity"
                        />
                        <span v-else>-</span>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="entitySelectedClasse"
                            :items="entityClasses"
                            class="mt-4"
                            outlined dense
                            :item-text="ob => ob.Descricao"
                            item-value="ID"
                            label="Classe *"
                            @change="changeClasse"
                        />
                    </v-col>
                    <v-col cols="12" md="auto">
                        Pesquisa:
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field
                            dense outlined
                            v-model="searchEntitySelected"
                            label="Nome/Número"
                        />
                    </v-col>
                    <v-col cols="12" md="2" align="center" justify="center">
                        <v-btn
                            class="success"
                            @click="getFuncionariosPorEntidade"
                        ><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="entitySelected != null">
                <!-- DESKTOP -->
                <v-virtual-scroll
                    class="d-none d-lg-block"
                    :height="getWindowHeight" 
                    item-height="75"
                    :items="funcionariosPorEntidade">
                    <template v-slot:default="{ item }">
                        <v-list-item>
                            <v-list-item-content>
                                <v-row>
                                <v-col cols="12" md="8">
                                    <v-text-field 
                                        dense outlined
                                        disabled
                                        v-model="item.funcionario"
                                        label="Funcionario"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-text-field 
                                        dense outlined
                                        v-model="item.hn"
                                        @keydown="item.selected = true"
                                        label="HN"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-text-field 
                                        dense outlined
                                        v-model="item.hext"
                                        @keydown="item.selected = true"
                                        label="HExt"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-text-field 
                                        dense outlined
                                        v-model="item.local"
                                        @keydown="item.selected = true"
                                        label="Nota"
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-switch 
                                        dense
                                        v-model="item.selected"
                                    />
                                </v-col>
                            </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
                <!-- DESKTOP -->

                <!-- MOBILE -->
                <v-virtual-scroll 
                    v-if="funcionariosPorEntidade.length > 0" 
                    class="d-md-none"
                    height="600" 
                    item-height="475"
                    :items="funcionariosPorEntidade">
                    <template v-slot:default="{ item }">
                        <v-card class="elevation-0" outlined>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-row>
                                    <v-col cols="12" md="8">
                                        <v-text-field 
                                            dense outlined
                                            disabled
                                            v-model="item.funcionario"
                                            label="Funcionario"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-text-field 
                                            dense outlined
                                            v-model="item.hn"
                                            @keydown="item.selected = true"
                                            label="HN"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-text-field 
                                            dense outlined
                                            v-model="item.hext"
                                            @keydown="item.selected = true"
                                            label="HExt"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-text-field 
                                            dense outlined
                                            v-model="item.local"
                                            @keydown="item.selected = true"
                                            label="Nota"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="1">
                                        <v-switch 
                                            dense
                                            v-model="item.selected"
                                        />
                                    </v-col>
                                </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </template>
                    <v-row>
                        <v-col cols="12">
                            <v-data-iterator
                                :items="funcionariosPorEntidade"
                                item-key="id"
                                :items-per-page="-1"
                                hide-default-footer
                                class="d-block d-lg-none ma-0 pa-0"
                            >
                            <template v-slot:default="{ items }">
                                <v-row class="ma-0 pa-0">
                                    <v-col
                                        class="ma-0 pa-0"
                                        v-for="(item, index) in items"
                                        :key="index+900000"
                                        cols="12"
                                    >
                                        <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                            <v-list
                                                dense
                                            >
                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            disabled
                                                            v-model="item.funcionario"
                                                            label="Funcionario"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            v-model="item.hn"
                                                            @keydown="item.selected = true"
                                                            label="HN"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            v-model="item.hext"
                                                            @keydown="item.selected = true"
                                                            label="HExt"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-text-field 
                                                            dense outlined
                                                            v-model="item.local"
                                                            @keydown="item.selected = true"
                                                            label="Nota"
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-switch 
                                                            v-model="item.selected"
                                                            label="Selecionar"
                                                            inset
                                                        />
                                                    </v-list-item-content>                                                                            
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                            </v-data-iterator>
                        </v-col>
                    </v-row>
                </v-virtual-scroll>
                <!-- MOBILE -->
            </v-card-text>
            <v-card-actions v-if="entitySelected != null">
                <v-row>
                    <v-col align="center" justify="center" cols="12">
                        <v-btn class="success" @click="addFuncionariosPorEntidade">Gravar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <SuccessSnackbar message="Colaboradores inseridos na diária" v-model="successAddEntity"/>

    <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
            <InputEditorModal @change-value="changeEditorModal" vid="editor_local" :title="'Nota'" :label="'Nota'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>
        

    <ErrorSnackbar v-model="error" :message="error_message"/>

    <ErrorSnackbar color="gold" :timeout="6000" v-model="userNaoExisteObra" :message="'[Alguns dados não foram importados] - Ao importar o template o sistema detetou que alguns dados do mesmo não estão associados à obra'"/>

    <ErrorSnackbar color="gold" :timeout="6000" v-model="erros.show" :message="erros.msg"/>

    <Loader v-if="loader"/>

    

    <DialogSuccess :opened="successDialog.show" @on-ok="successDialog.show = false; $router.push('/diarios/v2')" >
        {{successDialog.message}}
    </DialogSuccess>

    <DialogUpdatingDiary v-if="this.fields.locked"/>

    </v-container>
</template>
<script> 
import PDEnc from "@/api/PDEnc.js" 
import DateInput from "@/components/UI/Inputs/DateInput"
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import Obra from "@/api/Obra.js"
import FrenteObra from '@/api/FrenteObra.js'
import Entidades from '@/api/Entidades.js'
import Materiais from '@/api/Materiais.js'
import Classe from "@/api/Classe.js"
import Templates from "@/api/Templates.js"
import dayjs from 'dayjs'
import Equipments from '@/components/Diaries/Supervisor/Objects/Equipments.vue'
import Materials from '@/components/Diaries/Supervisor/Objects/Materials.vue'
import Subcontractors from '@/components/Diaries/Supervisor/Objects/Subcontractors.vue'
import Observations from '@/components/Diaries/Supervisor/Objects/Observations.vue'
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Loader from "@/components/UI/Loaders/Loader";
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import Diaries from '@/api/Diaries.js'
import DiaryKPI from '@/components/Diaries/Kpi/DiaryKPI.vue'
import DialogSuccess from '@/components/UI/DialogSuccess.vue';
import DiaryKPI_API from "@/api/DiaryKPI.js";
import Tooltip from '@/components/UI/Tooltip.vue';
import { readExcelFile } from "@/plugins/excelReader";
import DialogUpdatingDiary from '@/components/UI/DialogUpdatingDiary.vue'

export default{
    name:"Supervisor",
    components:{
        DialogSuccess,
        DiaryKPI,
        DateInput,
        Equipments,
        Materials,
        Subcontractors,
        Observations,
        ErrorSnackbar,
        Loader,
        SuccessSnackbar,
        InputEditorModal,
        Tooltip,
        DialogUpdatingDiary
    },
    props:{
        method: String,
        diary: null
    },
    watch: { 
        entitySelect:{
            immediate: true,
            handler(val){
                if(val == undefined)
                    return

                if(val == false)
                    this.entitySelected = null
            }
        },
        diary: {
            immediate: true, 
            async handler (val) {
                if(!val) {
                    return;
                }
        
                let data = {...val};
        
                this.fields = data;
                
                this.fields.funcionariosInternos = data.funcionariosInternos == null ? [] : data.funcionariosInternos.linhas
                this.fields.diary_kpi_header_id = data.diary_kpi_header_id
                
                this.fields.funcionariosInternos.forEach(item => {
                    // Inicializa fabOptions para cada item
                    this.$set(this.fabOptions, item.id, false); // false ou true dependendo do seu uso
                });
                this.fields.funcionariosInternos.forEach(element => {
                    element.hn = isNaN(element.hn) ? 0 : parseFloat(element.hn).toFixed(2)
                    element.hn = element.hn % 1 == 0 ? parseInt(element.hn) : element.hn

                    element.hext = isNaN(element.hext) ? 0 : parseFloat(element.hext).toFixed(2)
                    element.hext = element.hext % 1 == 0 ? parseInt(element.hext) : element.hext
                    
                    element.id = element.id + '-' + this.idIncrementor++

                    element.faturar_values = this.valores_faturacao;
                });

                this.fields.funcionariosExternos = data.funcionariosExternos == null ? [] : data.funcionariosExternos.linhas


                

                this.fields.funcionariosExternos.forEach(item => {
                    // Inicializa fabOptions para cada item
                    this.$set(this.fabOptions, item.id, false); // false ou true dependendo do seu uso
                });


                this.fields.funcionariosExternos.forEach(element => {
                    element.hn = isNaN(element.hn) ? 0 : parseFloat(element.hn).toFixed(2)
                    element.hn = element.hn % 1 == 0 ? parseInt(element.hn) : element.hn

                    element.hext = isNaN(element.hext) ? 0 : parseFloat(element.hext).toFixed(2)
                    element.hext = element.hext % 1 == 0 ? parseInt(element.hext) : element.hext

                    element.id = element.id + '-' + this.idIncrementor++

                    element.faturar_values = this.valores_faturacao;
                });

                this.fields.equipamentosInternos = data.equipamentosInternos == null ? [] : data.equipamentosInternos.linhas

                this.fields.equipamentosInternos.forEach(element => {
                    element.hs = isNaN(element.hs) ? 0 : parseFloat(element.hs).toFixed(2)
                    element.hs = element.hs % 1 == 0 ? parseInt(element.hs) : element.hs

                    element.hp = isNaN(element.hp) ? 0 : parseFloat(element.hp).toFixed(2)
                    element.hp = element.hp % 1 == 0 ? parseInt(element.hp) : element.hp

                    element.ho = isNaN(element.ho) ? 0 : parseFloat(element.ho).toFixed(2)
                    element.ho = element.ho % 1 == 0 ? parseInt(element.ho) : element.ho

                    element.ha = isNaN(element.ha) ? 0 : parseFloat(element.ha).toFixed(2)
                    element.ha = element.ha % 1 == 0 ? parseInt(element.ha) : element.ha

                    element.horas = isNaN(element.horas) ? 0 : parseFloat(element.horas).toFixed(2)
                    element.horas = element.horas % 1 == 0 ? parseInt(element.horas) : element.horas

                    element.km = isNaN(element.km) ? 0 : parseFloat(element.km).toFixed(2)
                    element.km = element.km % 1 == 0 ? parseInt(element.km) : element.km

                    element.faturar_values = this.valores_faturacao;
                });

                this.fields.equipamentosExternos = data.equipamentosExternos == null ? [] : data.equipamentosExternos.linhas

                this.fields.equipamentosExternos.forEach(element => {
                    element.hs = isNaN(element.hs) ? 0 : parseFloat(element.hs).toFixed(2)
                    element.hs = element.hs % 1 == 0 ? parseInt(element.hs) : element.hs

                    element.hp = isNaN(element.hp) ? 0 : parseFloat(element.hp).toFixed(2)
                    element.hp = element.hp % 1 == 0 ? parseInt(element.hp) : element.hp

                    element.ho = isNaN(element.ho) ? 0 : parseFloat(element.ho).toFixed(2)
                    element.ho = element.ho % 1 == 0 ? parseInt(element.ho) : element.ho

                    element.ha = isNaN(element.ha) ? 0 : parseFloat(element.ha).toFixed(2)
                    element.ha = element.ha % 1 == 0 ? parseInt(element.ha) : element.ha

                    element.horas = isNaN(element.horas) ? 0 : parseFloat(element.horas).toFixed(2)
                    element.horas = element.horas % 1 == 0 ? parseInt(element.horas) : element.horas

                    element.km = isNaN(element.km) ? 0 : parseFloat(element.km).toFixed(2)
                    element.km = element.km % 1 == 0 ? parseInt(element.km) : element.km

                    element.faturar_values = this.valores_faturacao;
                });
                
                this.fields.materiais = data.materiais

                data.materiais.forEach(element => {
                    element.qty = isNaN(element.qty) ? 0 : parseFloat(element.qty);

                });
                this.fields.subempreitadas = data.subempreitada

                this.fields.deleteMO =[];
                this.fields.deleteEQ =[];
                this.fields.deleteMaterial =[];
                this.fields.deleteSubempreitada =[];

                if(data.newObservationsClasses != null){
                    this.fields.obsMO = data.newObservationsClasses.obs_work_hand
                    this.fields.obsEQ = data.newObservationsClasses.obs_equipments
                }

                
                await this.getObras()

                this.changeWork()

                // Diária não tem frente mas tem unidade
                if(this.fields.frente_id == null && this.fields.classe_id != null){
                    this.classesPorObra()
                    this.changeClasse()
                }

                if(this.fields.classe_id != null){
                    this.changeClasse()
                }


                
                this.showContent = true
            }
        },
        loadUpdateMetadata:{
            immediate: true,
            handler(val){
                if(!val){
                    return;
                }

                this.changeFrenteObra()
            }
        }
    },
    data(){
        return{
            valores_faturacao: ['S', 'N', 'C'],
            diarias_manobrador: [],
            idIncrementor: 0,
            fabOptions: {},
            successDialog:{
                show: false,
                message: null
            },
            erros:{
                show: false,
                msg: ''
            },
            //Runner counters
            classesPorObraCounter: 0,
            entidadesPorObraCounter: 0,
            getFrenteObraCounter: 0,
            getObrasCounter: 0,
            getFuncionariosCounter: 0,
            // End runner counters
            successAddEntity: false,
            searchEntitySelected: null,
            funcionariosPorEntidade: [],
            entitySelected: null,
            entitySelectedFrente: null,
            entitySelect: false,
            entidades: [],
            menu2: false,
            tab: 0,
            userNaoExisteObra: false,
            hideFrentes: false,
            loadUpdateMetadata: false,
            loader: false,
            loading: true,
            error_message: null,
            linhas: [],
            error: false,
            obras: [],
            frentes_obra: [],
            classes: [],
            funcionarios_externos: [],
            funcionarios_internos: [],
            equipamentos: [],
            template: null,
            showContent: false,
            classeNeeded: false,
            entityClasses: [],
            entityClasseNeeded: false,
            entityHideFrentes: false,
            entitySelectedClasse: null,
            step: 10,
            paginacaoInterna:{
                menor: 0,
                maior: 10
            },
            paginacaoExterna:{
                menor: 0,
                maior: 10
            },
            diary_kpi: null,
            inputEditorModalData:{
                place: null,
                itemModel:null,
                id: null,
                classe_id: null,
                frente_id: null
            },   
            fields:{
                Data: null,
                obra_id: null,
                frente_id: null,
                classe_id: null,
                funcionariosInternos: [],
                funcionariosExternos: [],
                equipamentosInternos: [],
                equipamentosExternos: [],
                materiais:[],
                subempreitadas: [],
                obsMO: null,
                obsEQ: null,
                deleteMO: [],
                deleteEQ: [],
                deleteMaterial: [],
                deleteSubempreitada: [],
                work_order_obs: [],
                locked: false
            },
            editorModal:false,
            precisaSincronizarPrecos: false,
        }
    },
    mounted(){         
        this.fillBaseData()    
    },
    methods:{      
        triggerFileInputExternal() {
            this.$refs.fileInputExternal.click();
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        async handleFileUploadExternal(event) {
            const file = event.target.files[0];

            this.loader = true

            try {
                const data = await readExcelFile(file);
                console.log("Dados lidos do Excel");
                this.processExcelDataExternal(data);
            } catch (error) {
                alert(error.message);
            }
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];

            this.loader = true

            try {
                const data = await readExcelFile(file);
                console.log("Dados lidos do Excel");
                this.processExcelData(data);
            } catch (error) {
                alert(error.message);
            }
        },
        processExcelData(data) {
            let result = this.$excelValidator(data)
            if(result.success == false){
                alert(result.error);
                return
            }
            

            data = data.slice(1, -2)
            
            let available_classes = []
            let func_disponiveis = this.getFuncionariosInternosDisponiveis();
            

            let classeNeeded = false;
            if(this.hideFrentes == true){
                available_classes = this.classes;
                classeNeeded = true;
            }
            let tamanho = 0
            if(this.fields.funcionariosInternos != null)
                tamanho = this.fields.funcionariosInternos.length
            
                
            data.forEach(element => {

                let frente = null
                this.frentes_obra.forEach(frente_obra => {
                    if(frente_obra.Descricao == element[1]){
                        frente = frente_obra.ID
                        available_classes = frente_obra.classes
                        if(available_classes.length > 0) classeNeeded = true
                    }
                });
                
                if (typeof element[2] !== 'string') return;

                let var_classe = element[2] ? element[2].split('-')[0].trim() : null;
                if(var_classe != null)
                    var_classe = available_classes.find(obj => obj.Classe === var_classe);

                console.log("FRENTE: " + frente)
                
                if(element[3]){
                    if (func_disponiveis.some(f => f.Codigo === element[3].split('-')[0].trim())) {                        
                        this.fields.funcionariosInternos.push({
                            id: dayjs().unix() + '-' + tamanho + '-new',
                            funcionario: element[3] ? element[3].split('-')[0].trim() : null, // Extrai apenas o número
                            frente_id: frente,
                            classe_id: var_classe == null ? null : var_classe.ID, // Extrai apenas o número
                            classeNeeded: classeNeeded,
                            available_classes: available_classes,
                            hn: parseFloat(element[4]).toFixed(2),
                            hext: parseFloat(element[6]).toFixed(2),
                            funcionarios_disponiveis: func_disponiveis,
                            local: null,
                            faturar: 'N',
                            faturar_values: ['S', 'N', 'C'],
                            valor_h_normal: parseFloat(element[5]),
                            valor_h_extra: parseFloat(element[7]),
                            options: ""
                        })
                        tamanho = this.fields.funcionariosInternos.length
                    }
                }
            });

            this.loader = false

        },
        processExcelDataExternal(data){
            let result = this.$excelValidator(data)
            if(result.success == false){
                alert(result.error);
                return
            }

            

            data = data.slice(1, -2)
            
            let available_classes = []
            
            let classeNeeded = false;
            if(this.hideFrentes == true){
                available_classes = this.classes;
                classeNeeded = true;
            }
            let tamanho = 0
            if(this.fields.funcionariosExternos != null)
                tamanho = this.fields.funcionariosExternos.length
                
            data.forEach(element => {

                let frente = null
                this.frentes_obra.forEach(frente_obra => {
                    if(frente_obra.Descricao == element[1]){
                        frente = frente_obra.ID
                        available_classes = frente_obra.classes
                        if(available_classes.length > 0) classeNeeded = true
                    }
                });

                if (typeof element[2] !== 'string') return;
                
                let var_classe = element[2] ? element[2].split('-')[0].trim() : null;
                if(var_classe != null)
                    var_classe = available_classes.find(obj => obj.Classe === var_classe);
                
                if(element[3]){
                    if (this.funcionarios_externos.some(f => f.Numero === element[3].split('-')[0].trim())) {                
                        this.fields.funcionariosExternos.push({
                            id: dayjs().unix() + '-' + tamanho + '-new',
                            funcionario: element[3] ? element[3].split('-')[0].trim() : null, // Extrai apenas o número,
                            frente_id: frente,
                            classe_id: var_classe == null ? null : var_classe.ID,
                            funcionariosEntidades: [],
                            classeNeeded: classeNeeded,
                            available_classes: available_classes,
                            hn: parseFloat(element[4]).toFixed(2),
                            hext: parseFloat(element[6]).toFixed(2),
                            local: null,
                            faturar: 'N',
                            faturar_values: ['S', 'N', 'C'],
                            valor_h_normal: parseFloat(element[5]), 
                            valor_h_extra: parseFloat(element[7]),
                            options: ""
                        })
                        tamanho = this.fields.funcionariosExternos.length
                    }
                }
            });

            this.loader = false
        },
        getEspecialidade(place, cod){
            let returner = ''
            if(place == 'interno'){
                this.funcionarios_internos.forEach(element => {
                    if(element.Codigo == cod)
                        returner = element.Especialidade == null ? '' : element.Especialidade.Desig
                });
            }
            if(place == 'externo'){
                this.funcionarios_externos.forEach(element => {
                    if(element.numero == cod)
                        returner = element.Especialidade == null ? '' : element.Especialidade.Desig
                });
            }
            console.log(cod)
            return returner;
        },
        openNativeDatePicker() {
            const input = this.$refs.dateInput.$el.querySelector('input');
            if (input) {
                input.showPicker(); // Força a abertura do seletor de data no iPhone e Android
            }
        },
        async sincronizarPrecos(){
            this.loader = true

            await this.fields.funcionariosInternos.forEach(async item => {
                // Inicializa fabOptions para cada item
                await this.calculateMaoObraCost(item, 'i')
            });

            await this.fields.funcionariosExternos.forEach(async item => {
                // Inicializa fabOptions para cada item
                await this.calculateMaoObraCost(item, 'e')
            });

            await this.fields.equipamentosExternos.forEach(async item => {
                // Inicializa fabOptions para cada item
                await this.calculateEquipmentCost(item, 'e')
            });

            await this.fields.equipamentosInternos.forEach(async item => {
                // Inicializa fabOptions para cada item
                await this.calculateEquipmentCost(item, 'i')
            });


            //alert('Não se esqueça de gravar a diária para os dados ficarem corretos')

            this.loader = false
        },
        verificarCustosLinha(item){
            if(!this.$store.getters.hasRole(['super', 'administrativo', 'engenheiro']))
                return true;

            if(item.valor_h_normal == null && item.valor_h_extra == null){
                this.precisaSincronizarPrecos = true
                return false
            }

            return true
        },
        async calculateMaoObraCost(item, place){
            console.log("TOMA")
            console.log(item)
            let data = {
                data: this.fields.Data,
                mo_code: item.funcionario,
                obra_id: this.fields.obra_id
            }

            await Diaries.getPrecoMaoObra(data).then((resp)   =>  {
                if(resp.data.Result == false){
                    item.valor_h_normal = null
                    item.valor_h_extra = null
                    item.faturar = 'N'
                }else{                    
                    item.valor_h_normal = resp.data.PrecoServico
                    item.valor_h_extra = resp.data.PrecoOrdem
                    item.faturar = resp.data.Faturar
                }
                
                item.faturar_values = ['S', 'N', 'C']
            })
        },        
        async calculateEquipmentCost(item, place){
            let data = {
                data: this.fields.Data,
                eq_code: item.equipamento,
                obra_id: this.fields.obra_id
            }

            await Diaries.getPrecoEquipamento(data).then((resp)   =>  {
                if(resp.data.Result == false){
                    item.valor_h_normal = null
                    item.valor_h_extra = null
                    item.faturar = 'N'
                }else{                    
                    item.valor_h_normal = resp.data.PrecoNormal
                    item.valor_h_extra = resp.data.PrecoExtra
                    item.faturar = resp.data.Faturar
                }
                item.faturar_values = ['S', 'N', 'C']
            })
        },
        openEditorModal(item, place){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.inputEditorModalData.place = place
            this.inputEditorModalData.classe_id = item.classe_id
            this.inputEditorModalData.frente_id = item.frente_id

            this.editorModal=true
        },
        changeEditorModal(e){
            if(this.inputEditorModalData.place == 'funcionario_interno'){
                this.fields.funcionariosInternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id && element.classe_id == this.inputEditorModalData.classe_id && element.frente_id == this.inputEditorModalData.frente_id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'funcionario_externo'){
                this.fields.funcionariosExternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'equipamento_interno'){
                this.fields.equipamentosInternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'equipamento_externo'){
                this.fields.equipamentosExternos.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'material'){
                this.fields.materiais.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
            if(this.inputEditorModalData.place == 'subempreitada'){
                this.fields.subempreitadas.forEach(element => {
                    if(element.id == this.inputEditorModalData.id)
                        element.local = e
                });
            }
        },
        showFrente(item){
            if(this.fields.obra_id != null && this.hideFrentes == false)
                return true

            return false
        },
        showClasse(item){
            if(item.classeNeeded)
                return true

            return false
        },
        addFuncionariosPorEntidade(){
                //entity_classes
                // frentes_obra

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.entitySelectedFrente)
                    frente = element.Descricao
            });
            let classe = null
            this.entityClasses.forEach(element => {
                if(element.ID == this.entitySelectedClasse)
                    classe = element.Descricao
            });
            this.funcionariosPorEntidade.forEach(element => {
                if(element.selected){
                    this.fields.funcionariosExternos.push({
                        id: element.id + '-new',
                        funcionario: element.numero,
                        hn: element.hn,
                        frente_id: this.entitySelectedFrente,
                        classe_id: this.entitySelectedClasse,
                        classe_obra: classe == null ? '-' : classe,
                        frente_obra: frente == null ? '-' : frente,
                        hext: element.hext,
                        local: element.local,
                        options: "",
                        faturar: null,
                        faturar_values: ['S', 'N', 'C'],
                        valor_h_normal: null, 
                        valor_h_extra: null,
                        entidade: element.entidade
                    })
                }
            });

            this.getFuncionariosPorEntidade()

            this.entitySelected = null
            this.entitySelect = false
            this.successAddEntity = true
        },
        changedExterno(val){
            let ent = null
            this.funcionarios_externos.forEach(element => {
                if(element.Numero == val){
                    ent = element.entidade
                }
            });

            let pos = -1
            let i = 0
            this.fields.funcionariosExternos.forEach(element => {
                if(element.Numero == val || element.funcionario == val)
                    pos = i

                i++
            });

            if(pos != -1)
                this.fields.funcionariosExternos[pos].entidade = ent
        },
        toEditRow(item){
            if(item.id.includes('new'))
                return true

            return false
        },
        dataChanged(){
            if(this.obras.length == 1){
                this.fields.obra_id = this.obras[0].Id
                this.changeWork()
            }
        },
        async importTemplates(){
            this.loader = true
            // Carregar funcionarios internos

            let user_nao_existe_em_obra = false;

           
            this.template.funcionariosInternos.forEach(element => {
                let existe = false

                this.fields.funcionariosInternos.forEach(fInternos => {
                    if(fInternos.funcionario == element.Codigo)
                        existe = true
                });

                if(existe == false){
                    element.Codigo = element.Codigo.replace(/\s/g, '');
                    let external_object = null

                    this.funcionarios_internos.forEach(aux => {
                        if(element.Codigo == aux.Codigo){
                            external_object = aux
                        }
                    });

                    if(external_object != null){
                        let incrementor = this.fields.funcionariosInternos == null ? 0 : this.fields.funcionariosInternos.length

                        this.fields.funcionariosInternos.push({
                            id: dayjs().unix() + '-' + incrementor + '-new-mo-internal-' + this.idIncrementor++,
                            funcionario: element.Codigo,
                            frente_id: element.frente_id,
                            classe_id: element.classe_id,
                            frente_obra: element.frente_obra,
                            classe_obra: element.classe_obra,
                            hn: element.hn,
                            hext: element.hext,
                            local: element.local,
                            options: '',
                            funcionarios_disponiveis: this.getFuncionariosInternosDisponiveis()
                        })  
                    }else user_nao_existe_em_obra = true;
                }
            })

            // Carregar funcionarios externos
            this.template.funcionariosExternos.forEach(element => {               
                    element.numero = element.numero.replace(/\s/g, '');
                    let external_object = null

                    
                    this.funcionarios_externos.forEach(aux => {
                        if(element.funcionario == aux.ID){
                            external_object = aux
                        }
                    });

                    let existe = false

                    if(external_object != null)
                        this.fields.funcionariosExternos.forEach(fExternos => {
                            if(fExternos.funcionario == external_object.Numero)
                                existe = true
                        });

                    if(existe == false){
                        if(external_object != null){
                            let incrementor = this.fields.funcionariosExternos == null ? 0 : this.fields.funcionariosExternos.length

                            this.fields.funcionariosExternos.push({
                                id: dayjs().unix() + '-' + incrementor + '-new-mo-external-' + this.idIncrementor++,
                                funcionario: external_object.Numero,
                                frente_id: element.frente_id,
                                classe_id: element.classe_id,
                                frente_obra: element.frente_obra,
                                classe_obra: element.classe_obra,
                                entidade: external_object.entidade,
                                hn: element.hn,
                                hext: element.hext,
                                local: element.local,
                                options: ''
                            })  
                        }else user_nao_existe_em_obra = true;
                    }
            })
            
            // Carregar equipamentos internos
            this.template.equipamentosInternos.forEach(element => {

                let existe = false

                this.fields.equipamentosInternos.forEach(fEquipInternos => {
                    if(fEquipInternos.equipamento == element.equipamento)
                        existe = true
                });

                if(existe == false){
                    let incrementor = this.fields.equipamentosInternos == null ? 0 : this.fields.equipamentosInternos.length

                    this.fields.equipamentosInternos.push({
                        id: dayjs().unix() + '-' + incrementor + '-new-eq-internal-' + this.idIncrementor++,
                        codigo: null,
                        equipamento: element.equipamento,
                        frente_id: element.frente_id,
                        classe_id: element.classe_id,
                        frente_obra: element.frente_obra,
                        classe_obra: element.classe_obra,
                        hs: element.hs,
                        ho: element.ho,
                        ha: 0,
                        hp: element.hp,
                        local: null,
                        options: ""
                    });
                }
            });

            // Carregar equipamentos externos
            this.template.equipamentosExternos.forEach(element => {
                let existe = false

                this.fields.equipamentosExternos.forEach(fEquipExternos => {
                    if(fEquipExternos.equipamento == element.equipamento)
                        existe = true
                });

                if(existe == false){
                    let incrementor = this.fields.equipamentosExternos == null ? 0 : this.fields.equipamentosExternos.length

                    this.fields.equipamentosExternos.push({
                        id: dayjs().unix() + '-' + incrementor + '-new-eq-external-' + this.idIncrementor++,
                        codigo: null,
                        equipamento: element.equipamento,
                        frente_id: element.frente_id,
                        classe_id: element.classe_id,
                        frente_obra: element.frente_obra,
                        classe_obra: element.classe_obra,
                        hs: element.hs,
                        ho: element.ho,
                        ha: 0,
                        hp: element.hp,
                        local: null,
                        options: ""
                    });
                }
            });


            let materiais_list = []
            let materialsData ={
                id_frente_obra: this.fields.frente_id,
                id_obra_classe: this.fields.classe_id == null ? null : '1',
                obra_id: null
            }
            await Materiais.getList(materialsData).then((resp)    =>  {
                materiais_list = resp.data
            })

            this.template.materiais.forEach(element => {
                let material_object = null
                materiais_list.forEach(aux => {
                    if(aux.artigo == element.artigo)
                        material_object = aux
                });
                let incrementor = this.fields.materiais == null ? 0 : this.fields.materiais.length

                this.fields.materiais.push({
                    id: dayjs().unix() + '-' + incrementor + '-new-' + this.idIncrementor++,
                    material: material_object,
                    frente_id: element.frente_id,
                    classe_id: element.classe_id,
                    frente_obra: element.frente_obra,
                    classe_obra: element.classe_obra,
                    un: "-",
                    qty: 0,
                    local: null,
                    Unidade: '-',
                    options: ""
                });
            });

            if(user_nao_existe_em_obra){
                console.log("Imported data: 078956465")
                this.userNaoExisteObra = false
            }

            this.loader = false
        },
        changeClasse(){
            this.loading = true
            let object_data = {
                id: this.fields.obra_id,
                frente: this.fields.frente_id,
                unidade: this.fields.classe_id,
                short_id: false,
                obra_id: this.fields.obra_id
            }
            
            /*Obra.getObrasByClassFuncionarios(object_data).then((resp)  =>  {
                this.funcionarios_externos = resp.data.externo
                this.funcionarios_internos = resp.data.interno
            })*/

            this.loadTemplates()

            this.showContent = true
            this.loading = false
        },
        async changeFrenteObra(){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }

                await Classe.getClasses(data).then((resp) =>  {
                    this.classes = resp.data

                    if(this.$store.getters.hasRole(['parque']))
                        this.tab = 1


                    if(this.classes.length == 1){
                        this.fields.classe_id = this.classes[0].ID
                        this.changeClasse()
                    }
                })

                if(this.classes.length == 0){
                    this.showContent = true
                    this.classeNeeded = false
                }else this.classeNeeded = true
            }

            this.loadTemplates()
            
            this.loading = false
        },
        async changeFrenteObraV2(item){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == item.frente_id)
                    frente = element
            });


            let entrei = false
            if(frente != null){
                if(frente.classes != null){
                    if(frente.classes.length > 0){
                        entrei = true
                        item.available_classes = frente.classes
                        item.classeNeeded = true
                    }
                }

                if(entrei == false){
                    let data = {
                        SubEmp: frente.SubEmp
                    }


                    await Classe.getClasses(data).then((resp) =>  {
                        item.available_classes = resp.data

                        if(item.available_classes.length == 0){
                            item.classeNeeded = false
                        }else item.classeNeeded = true
                        
                    })
                }
                
            }

           
            
            this.loading = false
        },
        async changeFrenteObraV2Entity(){
            this.loading = true

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.entitySelectedFrente)
                    frente = element
            });

            if(frente != null){
                let data = {
                    SubEmp: frente.SubEmp
                }

                await Classe.getClasses(data).then((resp) =>  {
                    this.entityClasses = resp.data
                })

                if(this.entityClasses.length == 0){
                    this.entityClasseNeeded = false
                }else this.entityClasseNeeded = true
            }

            this.loadTemplates()
            
            this.loading = false
        },
        changeWork(){
            this.loading = true

            let data = {
                work_id: this.fields.obra_id,
                codigo: this.$store.state.user.data.funcionario.Codigo,
                place: 'encarregado'
            }
            
            this.getFrenteObra();

            this.getFuncionarios();

            this.loading = false


            this.loadTemplates()

            this.entidadesPorObra()

            this.loadManobradores();

            this.showContent = true
        },
        loadManobradores(){
            let data = {
                obra_id: this.fields.obra_id,
                data: this.fields.Data
            }

            Diaries.getDiariasManobradorEncarregado(data).then((resp)   =>  {
                this.diarias_manobrador = resp.data
            })
        },
        getFrenteObra(){
            if(this.getFrenteObraCounter > 0)
                return

            this.getFrenteObraCounter++

            let ObraPai = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    ObraPai = element.Codigo
            });

            FrenteObra.all(ObraPai).then((resp) =>  {
                this.frentes_obra = resp.data


                if(this.method=='update')
                    this.loadUpdateMetadata = true

                if(this.frentes_obra.length == 0)
                    this.classesPorObra()

                if(this.frentes_obra.length == 1){
                    this.fields.frente_id = this.frentes_obra[0].ID
                    this.changeFrenteObra()
                }
            })   
        },
        getFuncionarios(){
            if(this.getFuncionariosCounter > 0)
                return

            this.getFuncionariosCounter++
            let data = {
                id: this.fields.obra_id,
                frente: null
            }

            Obra.getObraFuncionarios(data).then((resp)  =>  {
                this.funcionarios_internos = resp.data
            })

            Obra.getObraPessoal(this.fields.obra_id).then((resp)    =>  {
                this.funcionarios_externos = resp.data
            })
        },
        entidadesPorObra(){
            if(this.entidadesPorObraCounter > 0)
                return 

            this.entidadesPorObraCounter++
            Entidades.byWork(this.fields.obra_id).then((resp)   =>  {
                this.entidades = resp.data.entidades
            })
        },
        classesPorObra(){
            if(this.classesPorObraCounter > 0)
                return;

            this.classesPorObraCounter++
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
                this.entityClasses = resp.data
                this.hideFrentes = true
                this.classeNeeded = true

                if(this.$store.getters.hasRole(['parque']))
                    this.tab = 1


                if(this.classes.length == 1){
                    this.fields.classe_id = this.classes[0].ID
                    this.changeClasse()
                }
               
            })
        },
        loadTemplates(){
            if(this.method == 'update')
                return
                
            let frente = null
            
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            let classe = null
            this.classes.forEach(element => {
                if(element.ID == this.fields.classe_id)
                    classe = element
            });

            // Ver se há templates
            let codesData = {
                classeId: classe == null ? null : classe.ClasseId,
                subEmpId: frente == null ? null : frente.SubEmpId,
                work_code: this.fields.obra_id
            }
            
            Templates.getByCodes(codesData).then(res => {
                if(res.data.template != null)
                    this.template =  res.data.template
            })
        },
        async getObras(){
            if(this.getObrasCounter > 0)
                return

            this.getObrasCounter++
            let data = {
                tipo: 'O'
            }
            
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
                
                if(this.method == 'update')
                    this.changeWork()   

            });
        },
        async fillBaseData(){
            await this.getObras();

            // Obter diary de KPI se existir
            if(this.method == 'update'){
                if(this.fields.diary_kpi_header_id != null){
                    DiaryKPI_API.find(this.fields.diary_kpi_header_id).then(({data}) => { 
                        this.diary_kpi = data; 
                    });
                }
            }
                    
                
            this.loading = false
        },
        deleteFuncionarioInterno(id){
            let pos = -1    
            let i = 0
            this.fields.funcionariosInternos.forEach(element => {
                if(element.id == id){
                    this.fields.deleteMO.push(element.primary_id)
                    pos = i
                }

                i++
            });

            //this.fields.deleteMO.push(id)

            this.fields.funcionariosInternos.splice(pos, 1)
        },
        deleteFuncionarioExterno(id){
            let pos = -1    
            let i = 0
            this.fields.funcionariosExternos.forEach(element => {
                if(element.id == id){
                    this.fields.deleteMO.push(element.primary_id)
                    pos = i
                }

                i++
            });

            this.fields.funcionariosExternos.splice(pos, 1)
        },
        addFuncionarioInterno(){
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes == true){
                available_classes = this.classes;
                classeNeeded = true;
            }
            let tamanho = 0
            if(this.fields.funcionariosInternos != null)
                tamanho = this.fields.funcionariosInternos.length

            this.fields.funcionariosInternos.push({
                id: dayjs().unix() + '-' + tamanho + '-new',
                funcionario: null,
                frente_id: null,
                classe_id: null,
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hn: parseFloat(8),
                hext: 0, //parseFloat(0),
                funcionarios_disponiveis: this.getFuncionariosInternosDisponiveis(),
                local: null,
                faturar: null,
                faturar_values: ['S', 'N', 'C'],
                valor_h_normal: null, 
                valor_h_extra: null,
                options: ""
            })
        },        
        getFuncionariosInternosDisponiveis(){
            let array_funcionarios = [];
            let array_used = [];

            this.fields.funcionariosInternos.forEach(element => {
                array_used.push(element.funcionario)
            });


            // Itera sobre todos os funcionários disponíveis
            this.funcionarios_internos.forEach((funcionario_available) => {
                let used = false;

                array_used.forEach(used_element => {
                    if(used_element == funcionario_available.Codigo)
                        used = true
                });

                if(used == false)
                    array_funcionarios.push(funcionario_available)                
            });

            return array_funcionarios
        },
        addFuncionarioExterno(){
            //this.entitySelect = false;
            //this.entitySelect = true;
            let available_classes = []
            let classeNeeded = false;
            if(this.hideFrentes == true){
                available_classes = this.classes;
                classeNeeded = true;
            }

            let tamanho = 0
            if(this.fields.funcionariosExternos != null)
                tamanho = this.fields.funcionariosExternos.length
            
            this.fields.funcionariosExternos.push({
                id: dayjs().unix() + '-' + tamanho + '-new',
                funcionario: null,
                frente_id: null,
                classe_id: null,
                funcionariosEntidades: [],
                classeNeeded: classeNeeded,
                available_classes: available_classes,
                hn: parseFloat(8).toFixed(2),
                hext: parseFloat(0).toFixed(2),
                local: null,
                faturar: null,
                faturar_values: ['S', 'N', 'C'],
                valor_h_normal: null, 
                valor_h_extra: null,
                options: ""
            })
        },
        submit(){
            this.loader = true;



            let obra = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    obra = element
            });



            let frente = null            
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            let classe = null
            this.classes.forEach(element => {
                if(element.ID == this.fields.classe_id)
                    classe = element
            });

            
            let data = {
                classeID: classe == null ? null : classe.ClasseId,
                id_user_creator: this.fields.id_user,
                SubEmpId: frente == null ? null : frente.SubEmpId,
                Codigo: obra.Codigo,
                classe: classe == null ? null : classe.ClasseId,    
                Data: this.fields.Data,
                maoObraInterna: this.fields.funcionariosInternos,
                maoObraExterna: this.fields.funcionariosExternos,
                equipamentoInterno: this.fields.equipamentosInternos,
                equipamentoExterno: this.fields.equipamentosExternos,
                material: this.fields.materiais,
                subEmpreitadas: this.fields.subempreitadas,
                obsMO: this.fields.obsMO,
                obsEQ: this.fields.obsEQ,
                hash: this.$route.params.hash,
                work_order_obs: this.fields.work_order_obs,
                toDelete:{
                    material: this.fields.deleteMaterial,
                    equipment: this.fields.deleteEQ,
                    MO: this.fields.deleteMO,
                    subEmpreitadas: this.fields.deleteSubempreitada
                }
            };
            

            // Verificar se há 1 linha de mao de obra externa/interna
            let arrays_vazios = 0
            if(this.fields.funcionariosInternos.length == 0)
                arrays_vazios++
            if(this.fields.funcionariosExternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosInternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosExternos.length == 0)
                arrays_vazios++

            if(arrays_vazios == 4){
                this.error_message = "Adicione pelo menos 1 equipamento ou mão de obra"
                this.error = true
                this.loading = false;
                this.loader = false;
                return
            }

            // Não podem existir horas de trabalho a zero
            // Vamos a todos os funcionarios
            // Internos e externos
            // Verificar as horas que eles têm registadas (Ho + Hext > 0)
            /*let existem_horas_a_zero = false;
            this.fields.funcionariosInternos.forEach(element => {
                let res = parseFloat(element.hn) + parseFloat(element.hext)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            this.fields.funcionariosExternos.forEach(element => {
                let res = parseFloat(element.hn) + parseFloat(element.hext)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            if(existem_horas_a_zero){
                this.error_message = 'Os funcionários não podem ter valores de trabalho a zero (Hn + Hext > 0)'
                this.error = true
                this.loader = false
                return
            }

            this.fields.equipamentosInternos.forEach(element => {
                let res = parseFloat(element.hs) + parseFloat(element.ho) + parseFloat(element.ha) + parseFloat(element.hp)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            this.fields.equipamentosExternos.forEach(element => {
                let res = parseFloat(element.hs) + parseFloat(element.ho) + parseFloat(element.ha) + parseFloat(element.hp)
                if(res <= 0)
                    existem_horas_a_zero = true
            });

            if(existem_horas_a_zero){
                this.error_message = 'Os equipamentos não podem ter valores de trabalho a zero (Hs + Ho + Ha + Hp > 0)'
                this.error = true
                this.loader = false
                return
            }*/

            // Horas tem de estar entre 0 e 40
            let horas_checked = true
            let horas_preenchidas = true
            this.fields.funcionariosInternos.forEach(element => {
                if(element.hn < 0 || element.hn > 40)
                    horas_checked = false     

                if(element.hext < 0 || element.hext > 40)
                    horas_checked = false       
                    
                if(element.hext == null)
                    horas_preenchidas = false


            });

            if(horas_checked == false){
                this.error_message = 'As horas dos funcionários internos devem estar compreendidas entre 0h e 40h'
                this.error = true
                this.loader = false
                return
            }

            if(horas_preenchidas == false){
                this.error_message = 'Existe Mão Obra Interna sem Horas Extra definidas'
                this.error = true
                this.loader = false
                return
            }
            this.fields.funcionariosExternos.forEach(element => {
                if(element.hn < 0 || element.hn > 40)
                    horas_checked = false      

                if(element.hext < 0 || element.hext > 40)
                    horas_checked = false                 
            });
            if(horas_checked == false){
                this.error_message = 'As horas dos FUNCIONÁRIOS externos devem estar compreendidas entre 0h e 40h'
                this.error = true
                this.loader = false
                return
            }

            this.fields.equipamentosInternos.forEach(element => {
                if(element.hs < 0 || element.hs > 40)
                    horas_checked = false  
                if(element.ho < 0 || element.ho > 40)
                    horas_checked = false  
                if(element.ha < 0 || element.ha > 40)
                    horas_checked = false  
                if(element.hp < 0 || element.hp > 40)
                    horas_checked = false  
            });
            if(horas_checked == false){
                this.error_message = 'As horas dos EQUIPAMENTOS internos devem estar compreendidas entre 0h e 40h'
                this.error = true
                this.loader = false
                return
            }

            this.fields.equipamentosExternos.forEach(element => {
                if(element.hs < 0 || element.hs > 40)
                    horas_checked = false  
                if(element.ho < 0 || element.ho > 40)
                    horas_checked = false  
                if(element.ha < 0 || element.ha > 40)
                    horas_checked = false  
                if(element.hp < 0 || element.hp > 40)
                    horas_checked = false  
            });
            if(horas_checked == false){
                this.error_message = 'As horas dos EQUIPAMENTOS externos devem estar compreendidas entre 0h e 40h'
                this.error = true
                this.loader = false
                return
            }
   

            PDEnc[this.method](data, data.hash).then(async (resp) => {
                this.success = true;

                this.error = false;

                this.loader = false;

                let link = resp.data
                
                if(this.$refs.diaria_kpi != undefined)
                    await this.$refs.diaria_kpi.submit(link.hash, link.creator)

                if(this.method == 'create'){
                    this.successDialog.message = "A sua diária foi enviado para o sistema e irá ser processada assim que possível. Iremos reencaminhá-lo para a lista das diárias. Brevemente terá disponível a diária que criou agora."
                    this.successDialog.show = true
                    //this.$router.push("/diarios/encarregado/editar/" + link.hash + "/" + link.creator);
                }else{
                    this.successDialog.message = "Diária enviada para atualização. Brevemente a informação será atualizada!"
                    this.successDialog.show = true
                    //window.location.reload()
                }

            }).catch(err => {
                console.log(err)

                if(err.response.status == 422){

                    this.$refs.pd.$refs.form.setErrors(err.response.data.errors);

                }
                
                this.success = false;

                this.error = true;

                this.error_message = err.response.data.message;

                this.loader = false;
            });
        },
        getFuncionariosPorEntidade(){
            let r = []

            this.funcionariosPorEntidade = []

            this.funcionarios_externos.forEach(element => {
                if(element.entidade != null){
                    if(element.entidade.ID == this.entitySelected){
                        let func = element.Numero + ' - ' + element.Nome
                        
                        let ob = null;
                        if(this.searchEntitySelected == null){
                            ob = {
                                    id: element.ID,
                                    funcionario: func,
                                    hn: 8,
                                    hext: 0, 
                                    local: null,
                                    selected: false,
                                    numero: element.Numero,
                                    entidade: element.entidade
                                }
                        }else{
                            if(func.toLowerCase().includes(this.searchEntitySelected.toLowerCase())){                                
                                ob = {
                                        id: element.ID,
                                        funcionario: func,
                                        hn: 8,
                                        hext: 0, 
                                        local: null,
                                        selected: false,
                                        numero: element.Numero,
                                        entidade: element.entidade
                                    }
                            }
                        }


                        let existe = false
                        if(ob != null){
                            this.fields.funcionariosExternos.forEach(aux => {
                                if(aux.funcionario == ob.numero)
                                    existe = true
                            });
                        }

                        if(!existe)
                            if(ob != null)
                                r.push(ob)
                    }
                }
            });


            this.funcionariosPorEntidade = r
        },
        validateDate(value) {
            if (!value) return "A data é obrigatória";
            if (value > this.maxDate) return "A data não pode ser futura";
            return true;
        },
    },
    computed:{        
        maxDate() {
            return new Date().toISOString().split("T")[0]; // Define a data máxima como hoje (YYYY-MM-DD)
        },
        approvedDiary(){
            if(this.method == 'create')
                return false;
            return this.fields.approved
        },
        computeFuncionariosExternos(){
            let returner = []

            this.fields.funcionariosExternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });

            return returner.slice(this.paginacaoExterna.menor, this.paginacaoExterna.maior)
        }, 
        computeFuncionariosInternos(){
            let returner = []

            // Vou mostrar dados de diárias de manobrador. Aqui vou inserir os funcionários quem vêm dessa diária
            this.diarias_manobrador.forEach(diaria => {
                diaria.parte_diaria.linhas_mo.forEach(element => {
                    returner.push({
                        id: dayjs().unix() + '-' + element.creator + '-new-non-editable',                    
                        funcionario: element.CodigoMO,
                        frente_id: diaria.frente_id,
                        classe_id: diaria.classe_id,
                        classeNeeded: diaria.classe_id == null ? false : true,
                        available_classes: this.classes,
                        hn: parseFloat(element.HNormal).toFixed(2),
                        hext: parseFloat(element.HExtra).toFixed(2),
                        funcionarios_disponiveis: this.getFuncionariosInternosDisponiveis(),
                        local: null,
                        faturar: null,
                        faturar_values: ['S', 'N', 'C'],
                        valor_h_normal: null,
                        valor_h_extra: null,
                        options: ''
                    })                    
                });
            });

            this.fields.funcionariosInternos.forEach(element => {
                if(element.classe_id != null){
                    if(this.hideFrentes == false){
                        this.frentes_obra.forEach(f => {
                            if(element.frente_id == f.ID){
                                element.available_classes = f.classes
                            }
                        });
                    }else{
                        element.available_classes = this.classes
                    }
                    element.classeNeeded = true
                }
                returner.push(element)
            });
            

            returner.funcionarios_disponiveis = this.getFuncionariosInternosDisponiveis()
            return returner.slice(this.paginacaoInterna.menor, this.paginacaoInterna.maior)
        },
        canSave(){
            // Verificar se há 1 linha de mao de obra externa/interna
            let arrays_vazios = 0
            if(this.fields.funcionariosInternos.length == 0)
                arrays_vazios++
            if(this.fields.funcionariosExternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosInternos.length == 0)
                arrays_vazios++
            if(this.fields.equipamentosExternos.length == 0)
                arrays_vazios++

            return arrays_vazios
        }, 
        getWindowHeight(){
            //Altura da janela menos 20%

            let calculo = window.innerHeight * 0.45
            
            return window.innerHeight - calculo
        },
    }
}
</script>
<style>

.my-grey{
    background-color: #ededed;
}

.my-orange {
    background-color: #FFE5CC;
}

.my-orange:hover {
    background-color: #FFE5CC;
}

</style>